import { useEffect } from 'react'

import { useAuth } from 'src/providers/AuthProvider'

import { Page } from 'src/components/layouts/Page'

import { dashboardPath } from 'src/config/paths'

const Login = () => {
  const { loginWithCustomRedirect } = useAuth()

  useEffect(() => {
    loginWithCustomRedirect(dashboardPath())
  }, [loginWithCustomRedirect])

  return <Page title='Login'>{null}</Page>
}

export default Login
